<template>
  <div class="van-main intelligence-list">
    <van-search
      v-model="searchForm.cargoName"
      class="van-main-search"
      shape="round"
      left-icon=""
      show-action
      placeholder="请输入货物名称"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">
          搜索
        </div>
      </template>
    </van-search>
    <div class="van-refresh-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="onLoad"
        >
          <van-radio-group v-model="selectedId">
            <div v-for="item in list" :key="item.id" class="intelligence-item">
              <p><span>货物名称</span><span>{{ item.cargoName }}</span></p>
              <p><span>所属一级分类</span><span>{{ item.cargoClassifyName }}</span></p>
              <p><span>所属二级分类</span><span>{{ item.cargoClassifyParentName }}</span></p>
              <p><span>原产地</span><span>{{ item.place }}</span></p>
              <p><span>现货价CNY/KG</span><span>{{ utils.moneyFormat(item.money) }}</span></p>
              <p><span>当前更新时间</span><span>{{ item.updateTime.split(' ')[0] || utils.dateFormat(item.updateTime) }}</span></p>
              <van-radio :name="item.id" class="radio" @click="select(item )" />
              <van-button plain round size="small" type="info" native-type="button" @click="detail(item)">
                查看更新记录
              </van-button>
              <van-divider />
            </div>
          </van-radio-group>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="button-area">
      <van-button square type="info" native-type="button" @click="add">
        新增价格信息
      </van-button>
      <van-button square type="info" native-type="button" @click="edit">
        更新价格信息
      </van-button>
    </div>
    <van-popup v-model="showAddPopup" round position="bottom">
      <van-nav-bar
        title="新增价格信息"
      />
      <van-cell title="所属一级分类" is-link :value="firstLevelCategoryText" @click="showFirstLevelCategory=true" />
      <van-popup v-model="showFirstLevelCategory" round position="bottom">
        <van-picker
          show-toolbar
          value-key="classifyName"
          title="所属一级分类"
          :columns="firstLevelCategory"
          @cancel="showFirstLevelCategory = false"
          @confirm="confirmFirstLevelCategory"
        />
      </van-popup>
      <van-cell title="所属二级分类" is-link :value="secondLevelCategoryText" @click="showSecondLevelCategory=true" />
      <van-popup v-model="showSecondLevelCategory" round position="bottom">
        <van-picker
          show-toolbar
          value-key="classifyName"
          title="所属二级分类"
          :columns="secondLevelCategory"
          @cancel="showSecondLevelCategory = false"
          @confirm="confirmSecondLevelCategory"
        />
      </van-popup>
      <van-cell title="选择货物名称" is-link :value="cargoText" :required="true" @click="showCargoList=true" />
      <van-popup v-model="showCargoList" round position="bottom">
        <van-picker
          show-toolbar
          value-key="cargoName"
          title="货物名称"
          :columns="cargoList"
          @cancel="showCargoList = false"
          @confirm="confirmCargo"
        />
      </van-popup>
      <van-cell title="选择原产地" is-link :value="placeText" :required="true" @click="showPlaceList=true" />
      <van-popup v-model="showPlaceList" round position="bottom">
        <van-picker
          show-toolbar
          value-key="place"
          title="原产地"
          :columns="placeOriginList"
          @cancel="showPlaceList = false"
          @confirm="confirmPlace"
        />
      </van-popup>
      <!-- <van-field v-model="addForm.place" label="原产地" label-width="9.2em" :required="true" placeholder="请输入" /> -->
      <van-field v-model="addForm.money" type="number" label="现货价（CNY/KG）" label-width="9.2em" placeholder="请输入" />
      <van-cell title="更新时间" :value="utils.dateTimeFormat(new Date())" />
      <div class="edit-button">
        <van-button round plain type="info" native-type="submit" @click="showAddPopup=false">
          取消
        </van-button>
        <van-button round type="info" native-type="submit" @click="submitAdd">
          确定
        </van-button>
      </div>
    </van-popup>
    <van-popup v-model="showEditPopup" round position="bottom">
      <van-nav-bar
        title="更新价格信息"
      />
      <van-cell title="所属一级分类" :value="firstLevelCategoryText" />
      <van-cell title="所属二级分类" :value="secondLevelCategoryText" />
      <van-cell title="货物名称" :value="cargoText" />
      <van-cell title="原产地" :value="placeText" />
      <van-field v-model="editForm.money" type="number" label="现货价（CNY/KG）" label-width="9.2em" placeholder="请输入" />
      <van-cell title="更新时间" :value="utils.dateTimeFormat(new Date())" />
      <div class="edit-button">
        <van-button round plain type="info" native-type="submit" @click="showEditPopup=false">
          取消
        </van-button>
        <van-button round type="info" native-type="submit" @click="submitUpdate">
          确定
        </van-button>
      </div>
    </van-popup>
    <back-button />
  </div>
</template>

<script>
import BackButton from '@/components/back-button'
import { Dialog, Cell, CellGroup, Search, List, Divider, Button, Radio, RadioGroup, Form, Field, Toast, Popup, Picker, NavBar, PullRefresh } from 'vant'
export default {
  components: {
    BackButton,
    [Dialog.Component.name]: Dialog.Component,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [List.name]: List,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Toast.name]: Toast,
    [NavBar.name]: NavBar,
    [PullRefresh.name]: PullRefresh
  },
  data () {
    return {
      showAddPopup: false,
      showEditPopup: false,
      searchForm: {
        cargoName: '',
        pageNum: 1,
        pageSize: 10
      },
      loading: false,
      finished: false,
      refreshing: false,
      finishedText: '没有更多了',
      tableList: {
        list: []
      },
      list: [],
      selectedId: '',
      selectRow: {},
      firstLevelCategory: [],
      secondLevelCategory: [],
      cargoList: [],
      addForm: {
        cargoClassifyParentId: '',
        cargoClassifyId: '',
        cargoId: '',
        cargoName: '',
        place: '',
        money: ''
      },
      showFirstLevelCategory: false,
      firstLevelCategoryText: '请选择一级分类',
      showSecondLevelCategory: false,
      secondLevelCategoryText: '请选择二级分类',
      showCargoList: false,
      cargoText: '请选择货物名称',
      showPlaceList: false,
      placeText: '请选择原产地',
      editForm: {
        id: this.$route.query.id,
        cargoId: '',
        money: ''
      },
      placeOriginList: []
    }
  },
  created () {
    this.getCategoryList()
  },
  methods: {
    onSearch () {
      this.searchForm.pageNum = 1
      this.finishedText = '没有更多了'
      this.getList()
    },
    getList () {
      this.api.intelligence.getPageRecord(this.searchForm).then(result => {
        this.tableList = result.data.value
        if (this.tableList.pages === 0 && this._.isEmpty(this.tableList.list)) {
          this.list = []
          this.finishedText = '暂无数据'
          this.finished = true
        } else {
          if (this.tableList.pageNum === 1) {
            this.list = result.data.value.list
          } else {
            this.list = this.list.concat(result.data.value.list)
          }
          this.loading = false
          if (this.tableList.pageNum < this.tableList.pages) {
            this.searchForm.pageNum += 1
          } else {
            this.finished = true
          }
        }
      }).catch(() => {
        this.finished = true
        this.refreshing = false
        this.finishedText = '暂无数据'
      }).finally(() => {
        this.refreshing = false
      })
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      this.loading = true
      this.searchForm.pageNum = 1
      this.getList()
    },
    onLoad () {
      this.getList()
    },
    detail (row) {
      this.$router.push(
        {
          name: 'intelligenceDetail',
          query:
          {
            id: row.id,
            cargoClassifyParentName: row.cargoClassifyParentName,
            cargoClassifyName: row.cargoClassifyName,
            cargoName: row.cargoName,
            place: row.place
          }
        }
      )
    },
    add () {
      this.showAddPopup = true
      this.addForm.cargoClassifyParentId = ''
      this.addForm.cargoClassifyId = ''
      this.addForm.cargoId = ''
      this.addForm.cargoName = ''
      this.addForm.place = ''
      this.addForm.money = ''
      this.firstLevelCategoryText = '请选择一级分类'
      this.secondLevelCategoryText = '请选择二级分类'
      this.cargoText = '请选择货物名称'
      this.placeText = '请选择原产地'
      console.log(this.showPlaceList)
    },
    select (row) {
      this.selectRow = row
    },
    edit () {
      if (!this.selectedId) {
        Toast.fail('请选择货物信息')
        return false
      }
      this.editForm.id = this.selectRow.id
      this.editForm.money = this.selectRow.money
      this.editForm.cargoId = this.selectRow.cargoId
      this.firstLevelCategoryText = this.selectRow.cargoClassifyParentName
      this.secondLevelCategoryText = this.selectRow.cargoClassifyName
      this.cargoText = this.selectRow.cargoName
      this.placeText = this.selectRow.place
      this.showEditPopup = true
    },
    // 一级分类
    getCategoryList () {
      this.api.cargoManagement.categoryList({ cargoParentId: 0 }).then(result => {
        const list = result.data.value || []
        list.forEach((item, index) => {
          list[index].subList = item.children
          delete list[index].children
        })
        this.firstLevelCategory = list
      })
    },
    // 二级分类
    changeFirstCategory (val) {
      const data = this._.find(this.firstLevelCategory, { cargoClassifyId: val })
      this.secondLevelCategory = data.subList || []
    },
    changeSecondCategory () {
      this.cargoList = []
      this.api.cargoManagement.goodsList({ cargoClassifyId: this.addForm.cargoClassifyId }).then(result => {
        this.cargoList = result.data.value
      })
    },
    getPlaceOriginList () {
      this.placeOriginList = []
      this.api.intelligence.getPlaceOriginList(this.addForm.cargoId).then(result => {
        this.placeOriginList = result.data.value
      })
    },
    confirmFirstLevelCategory (row) {
      this.addForm.cargoClassifyParentId = row.cargoClassifyId
      this.firstLevelCategoryText = row.classifyName
      this.changeFirstCategory(row.cargoClassifyId)
      this.showFirstLevelCategory = false
    },
    confirmSecondLevelCategory (row) {
      this.addForm.cargoClassifyId = row.cargoClassifyId
      this.secondLevelCategoryText = row.classifyName
      this.changeSecondCategory()
      this.showSecondLevelCategory = false
    },
    confirmCargo (row) {
      this.addForm.cargoId = row.cargoId
      this.cargoText = row.cargoName
      this.addForm.cargoName = row.cargoName
      this.getPlaceOriginList()
      this.showCargoList = false
    },
    confirmPlace (row) {
      this.placeText = row
      this.addForm.place = row
      this.showPlaceList = false
    },
    submitAdd () {
      if (this.addForm.cargoId === '') {
        Toast.fail('请选择货物')
        return false
      }
      if (this.addForm.place === '') {
        Toast.fail('请选择原产地')
        return false
      }
      this.api.intelligence.marketMonitoringAdd(this.addForm).then(result => {
        Toast.success(result.data.message || '提交成功')
        this.onSearch()
        this.showAddPopup = false
      }).finally(() => {
      })
    },
    submitUpdate () {
      if (this.editForm.money === '') {
        Toast.fail('请输入价格')
        return false
      }
      this.api.intelligence.marketMonitoringupdate(this.editForm).then(result => {
        Toast.success(result.data.message || '提交成功')
        this.onSearch()
        this.selectedId = ''
        this.showEditPopup = false
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
.intelligence-list {
  .van-nav-bar{
    position: static;
  }
  .van-list {
    padding-bottom: 110px;
  }
  .intelligence-item {
    font-size: 14px;
    margin-top: 10px;
    position: relative;
    p {
      margin-left: 10px;
      >span {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        vertical-align: middle;
      }
      >span:first-child {
        width: 110px;
        text-align: right;
      }
      >span:nth-child(2) {
        margin-left: 15px;
        overflow: hidden;
      }
    }
    .radio {
      position: absolute;
      left: 0px;
      top:calc(50% - 15px);
    }
    button {
      position: absolute;
      height: 30px;
      top: calc(50% - 15px);
      right: 15px;
    }
    .van-divider {
      margin-top: 5px;
    }

  }
  .button-area {
    position: fixed;
    bottom: 50px;
    width: calc(100% - 20px);
    left: 50%;
    margin-left: calc(-50% + 10px);
    padding-bottom: 15px;
    background-color: #FFF;
    z-index: 99;
    >button {
      width: calc(50% - 5px);
    }
    >button:last-child {
      margin-left: 10px;
    }
  }
  .edit-button {
    margin-top: 5px;
    margin-bottom: 20px;
    text-align: center;
    >button {
      width: 100px;
      margin: 0 10px;
    }
  }
}

</style>
