var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main intelligence-list" },
    [
      _c("van-search", {
        staticClass: "van-main-search",
        attrs: {
          shape: "round",
          "left-icon": "",
          "show-action": "",
          placeholder: "请输入货物名称"
        },
        on: { search: _vm.onSearch },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function() {
              return [
                _c("div", { on: { click: _vm.onSearch } }, [_vm._v(" 搜索 ")])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.searchForm.cargoName,
          callback: function($$v) {
            _vm.$set(_vm.searchForm, "cargoName", $$v)
          },
          expression: "searchForm.cargoName"
        }
      }),
      _c(
        "div",
        { staticClass: "van-refresh-list" },
        [
          _c(
            "van-pull-refresh",
            {
              on: { refresh: _vm.onRefresh },
              model: {
                value: _vm.refreshing,
                callback: function($$v) {
                  _vm.refreshing = $$v
                },
                expression: "refreshing"
              }
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": _vm.finishedText
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                [
                  _c(
                    "van-radio-group",
                    {
                      model: {
                        value: _vm.selectedId,
                        callback: function($$v) {
                          _vm.selectedId = $$v
                        },
                        expression: "selectedId"
                      }
                    },
                    _vm._l(_vm.list, function(item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "intelligence-item" },
                        [
                          _c("p", [
                            _c("span", [_vm._v("货物名称")]),
                            _c("span", [_vm._v(_vm._s(item.cargoName))])
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("所属一级分类")]),
                            _c("span", [_vm._v(_vm._s(item.cargoClassifyName))])
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("所属二级分类")]),
                            _c("span", [
                              _vm._v(_vm._s(item.cargoClassifyParentName))
                            ])
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("原产地")]),
                            _c("span", [_vm._v(_vm._s(item.place))])
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("现货价CNY/KG")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.utils.moneyFormat(item.money)))
                            ])
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("当前更新时间")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  item.updateTime.split(" ")[0] ||
                                    _vm.utils.dateFormat(item.updateTime)
                                )
                              )
                            ])
                          ]),
                          _c("van-radio", {
                            staticClass: "radio",
                            attrs: { name: item.id },
                            on: {
                              click: function($event) {
                                return _vm.select(item)
                              }
                            }
                          }),
                          _c(
                            "van-button",
                            {
                              attrs: {
                                plain: "",
                                round: "",
                                size: "small",
                                type: "info",
                                "native-type": "button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.detail(item)
                                }
                              }
                            },
                            [_vm._v(" 查看更新记录 ")]
                          ),
                          _c("van-divider")
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button-area" },
        [
          _c(
            "van-button",
            {
              attrs: { square: "", type: "info", "native-type": "button" },
              on: { click: _vm.add }
            },
            [_vm._v(" 新增价格信息 ")]
          ),
          _c(
            "van-button",
            {
              attrs: { square: "", type: "info", "native-type": "button" },
              on: { click: _vm.edit }
            },
            [_vm._v(" 更新价格信息 ")]
          )
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.showAddPopup,
            callback: function($$v) {
              _vm.showAddPopup = $$v
            },
            expression: "showAddPopup"
          }
        },
        [
          _c("van-nav-bar", { attrs: { title: "新增价格信息" } }),
          _c("van-cell", {
            attrs: {
              title: "所属一级分类",
              "is-link": "",
              value: _vm.firstLevelCategoryText
            },
            on: {
              click: function($event) {
                _vm.showFirstLevelCategory = true
              }
            }
          }),
          _c(
            "van-popup",
            {
              attrs: { round: "", position: "bottom" },
              model: {
                value: _vm.showFirstLevelCategory,
                callback: function($$v) {
                  _vm.showFirstLevelCategory = $$v
                },
                expression: "showFirstLevelCategory"
              }
            },
            [
              _c("van-picker", {
                attrs: {
                  "show-toolbar": "",
                  "value-key": "classifyName",
                  title: "所属一级分类",
                  columns: _vm.firstLevelCategory
                },
                on: {
                  cancel: function($event) {
                    _vm.showFirstLevelCategory = false
                  },
                  confirm: _vm.confirmFirstLevelCategory
                }
              })
            ],
            1
          ),
          _c("van-cell", {
            attrs: {
              title: "所属二级分类",
              "is-link": "",
              value: _vm.secondLevelCategoryText
            },
            on: {
              click: function($event) {
                _vm.showSecondLevelCategory = true
              }
            }
          }),
          _c(
            "van-popup",
            {
              attrs: { round: "", position: "bottom" },
              model: {
                value: _vm.showSecondLevelCategory,
                callback: function($$v) {
                  _vm.showSecondLevelCategory = $$v
                },
                expression: "showSecondLevelCategory"
              }
            },
            [
              _c("van-picker", {
                attrs: {
                  "show-toolbar": "",
                  "value-key": "classifyName",
                  title: "所属二级分类",
                  columns: _vm.secondLevelCategory
                },
                on: {
                  cancel: function($event) {
                    _vm.showSecondLevelCategory = false
                  },
                  confirm: _vm.confirmSecondLevelCategory
                }
              })
            ],
            1
          ),
          _c("van-cell", {
            attrs: {
              title: "选择货物名称",
              "is-link": "",
              value: _vm.cargoText,
              required: true
            },
            on: {
              click: function($event) {
                _vm.showCargoList = true
              }
            }
          }),
          _c(
            "van-popup",
            {
              attrs: { round: "", position: "bottom" },
              model: {
                value: _vm.showCargoList,
                callback: function($$v) {
                  _vm.showCargoList = $$v
                },
                expression: "showCargoList"
              }
            },
            [
              _c("van-picker", {
                attrs: {
                  "show-toolbar": "",
                  "value-key": "cargoName",
                  title: "货物名称",
                  columns: _vm.cargoList
                },
                on: {
                  cancel: function($event) {
                    _vm.showCargoList = false
                  },
                  confirm: _vm.confirmCargo
                }
              })
            ],
            1
          ),
          _c("van-cell", {
            attrs: {
              title: "选择原产地",
              "is-link": "",
              value: _vm.placeText,
              required: true
            },
            on: {
              click: function($event) {
                _vm.showPlaceList = true
              }
            }
          }),
          _c(
            "van-popup",
            {
              attrs: { round: "", position: "bottom" },
              model: {
                value: _vm.showPlaceList,
                callback: function($$v) {
                  _vm.showPlaceList = $$v
                },
                expression: "showPlaceList"
              }
            },
            [
              _c("van-picker", {
                attrs: {
                  "show-toolbar": "",
                  "value-key": "place",
                  title: "原产地",
                  columns: _vm.placeOriginList
                },
                on: {
                  cancel: function($event) {
                    _vm.showPlaceList = false
                  },
                  confirm: _vm.confirmPlace
                }
              })
            ],
            1
          ),
          _c("van-field", {
            attrs: {
              type: "number",
              label: "现货价（CNY/KG）",
              "label-width": "9.2em",
              placeholder: "请输入"
            },
            model: {
              value: _vm.addForm.money,
              callback: function($$v) {
                _vm.$set(_vm.addForm, "money", $$v)
              },
              expression: "addForm.money"
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "更新时间",
              value: _vm.utils.dateTimeFormat(new Date())
            }
          }),
          _c(
            "div",
            { staticClass: "edit-button" },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    round: "",
                    plain: "",
                    type: "info",
                    "native-type": "submit"
                  },
                  on: {
                    click: function($event) {
                      _vm.showAddPopup = false
                    }
                  }
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "van-button",
                {
                  attrs: { round: "", type: "info", "native-type": "submit" },
                  on: { click: _vm.submitAdd }
                },
                [_vm._v(" 确定 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.showEditPopup,
            callback: function($$v) {
              _vm.showEditPopup = $$v
            },
            expression: "showEditPopup"
          }
        },
        [
          _c("van-nav-bar", { attrs: { title: "更新价格信息" } }),
          _c("van-cell", {
            attrs: { title: "所属一级分类", value: _vm.firstLevelCategoryText }
          }),
          _c("van-cell", {
            attrs: { title: "所属二级分类", value: _vm.secondLevelCategoryText }
          }),
          _c("van-cell", {
            attrs: { title: "货物名称", value: _vm.cargoText }
          }),
          _c("van-cell", { attrs: { title: "原产地", value: _vm.placeText } }),
          _c("van-field", {
            attrs: {
              type: "number",
              label: "现货价（CNY/KG）",
              "label-width": "9.2em",
              placeholder: "请输入"
            },
            model: {
              value: _vm.editForm.money,
              callback: function($$v) {
                _vm.$set(_vm.editForm, "money", $$v)
              },
              expression: "editForm.money"
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "更新时间",
              value: _vm.utils.dateTimeFormat(new Date())
            }
          }),
          _c(
            "div",
            { staticClass: "edit-button" },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    round: "",
                    plain: "",
                    type: "info",
                    "native-type": "submit"
                  },
                  on: {
                    click: function($event) {
                      _vm.showEditPopup = false
                    }
                  }
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "van-button",
                {
                  attrs: { round: "", type: "info", "native-type": "submit" },
                  on: { click: _vm.submitUpdate }
                },
                [_vm._v(" 确定 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }